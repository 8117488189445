import "./App.scss";
import { useEffect, useMemo, useState } from "react";
import {
  DATA_ENTERED_INTERACTION,
  logDataSubmitted,
  OPENED_INTERACTION,
} from "./Api";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";

export function AppWrapper() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/oauth" element={<App />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <div className="App">
      <HeaderBanner />
      <AuthContent />
      <Footer />
    </div>
  );
}

function AuthContent() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [searchParams] = useSearchParams();

  const trackingMeta = useMemo(() => {
    const raw = searchParams.get("data");
    try {
      return JSON.parse(atob(raw)); // base64 decode
    } catch {
      return null;
    }
  }, [searchParams]);

  // Log click on initial load (i.e., user arrived at the page)
  useEffect(() => {
    if (trackingMeta) {
      logDataSubmitted(
        trackingMeta.TrackingURL,
        trackingMeta.PeopleID,
        trackingMeta.Domain,
        trackingMeta.CampaignID,
        OPENED_INTERACTION
      );
    }
  }, [trackingMeta]);

  const handleSubmit = () => {
    if (trackingMeta) {
      logDataSubmitted(
        trackingMeta.TrackingURL,
        trackingMeta.PeopleID,
        trackingMeta.Domain,
        trackingMeta.CampaignID,
        DATA_ENTERED_INTERACTION
      );
    }
  };

  return (
    <div className="auth-content">
      <div className="auth-header">
        <h2>ACME Tech</h2>
      </div>
      <div className="auth-content-inner">
        <div className="auth-body">
          <h3>Sign In</h3>
          <FieldInputWithLabel
            className="username-input"
            label="Username"
            inputType="text"
            value={username}
            onTextChange={setUsername}
          />
          <FieldInputWithLabel
            className="password-input"
            label="Password"
            inputType="password"
            value={password}
            onTextChange={setPassword}
          />
          <div className="remember-me">
            <div className="custom-checkbox">
              <input id="rememberMe" type="checkbox" name="rememberMe" />
              <label for="rememberMe">Keep me signed in</label>
            </div>
          </div>
          <div className="submit-button-container">
            <input
              className="submit-button"
              type="submit"
              value="Sign in"
              onClick={handleSubmit}
            />
          </div>
        </div>
        <div className="auth-footer">
          <a
            data-se="forgot-password"
            className="link"
            href="https://applovin.okta.com/help/login"
          >
            Forgot password?
          </a>
          <a
            data-se="help"
            className="link"
            href="https://applovin.okta.com/help/login"
          >
            Help
          </a>
        </div>
      </div>
    </div>
  );
}

function FieldInputWithLabel({
  className,
  label,
  value,
  onTextChange,
  inputType,
}) {
  const handleChange = (event) => {
    onTextChange(event.target.value);
  };

  return (
    <div className={`field-input-container ${className}`}>
      <div className="label-container">
        <label>{label}</label>
      </div>
      <div className="text-input-container">
        <input type={inputType} value={value} onChange={handleChange} />
      </div>
    </div>
  );
}

function HeaderBanner() {
  return (
    <div className="app-header-banner">
      <h1>Connecting</h1>
      <p>Sign in with your account to access Okta Dashboard</p>
    </div>
  );
}

function Footer() {
  return (
    <div className="footer-container">
      <div className="app-footer">
        <p id="powered-by-okta" className="footer-link">
          Powered by Okta
        </p>
        <p id="privacy-policy" className="footer-link">
          Privacy Policy
        </p>
      </div>
    </div>
  );
}

export default App;
