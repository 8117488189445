export const DATA_ENTERED_INTERACTION = "DATA_ENTERED";
export const OPENED_INTERACTION = "OPENED";

export function logDataSubmitted(
  targetUrl,
  userId,
  domain,
  campaignId,
  interaction
) {
  fetch(`${targetUrl}/walnut/interaction`, {
    method: "POST",
    body: JSON.stringify({
      user_id: userId,
      campaign_id: campaignId,
      domain,
      interaction,
    }),
  }).then((result) => {
    console.log(result.status);
  });
}
